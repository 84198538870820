






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InsCmsCat4 extends Vue {
  // @Prop() private catId!: string;
  contentList: object[] = []; // cms内容列表
  currentPage: number = 1; // 当前页
  pageSize: number = 3; // 每页显示条目个数
  totalRecord: number = 0;// 总条目数

  // 获取 Category 和所有 SubCategory 的 cms 列表
  getSubCatContents () {
    this.$Api.cms
      .getSubCatContents({ CatId: this.id, Page: this.currentPage, PageSize: this.pageSize, IsMobile: this.isMobile })
      .then(result => {
        this.contentList = result.Data;
        this.totalRecord = result.TotalRecord;
        console.log(result, ' 获取 Category 和所有 SubCategory 的 cms 列表');
      });
  }

  get id () {
    console.log(this.$route.params.id, '当前id');
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  handleSizeChange (val) {
    console.log(`每页 ${val} 条`);
  }

  handleCurrentChange (val) {
    this.currentPage = val;
    console.log(`当前页: ${val}`);
    this.getSubCatContents();
    document.body.scrollTop = document.documentElement.scrollTop = 250;
  }

  mounted () {
    this.getSubCatContents();
  }
  @Watch('id', { deep: true })
  onKeyChange () {
    this.contentList = [];
    this.currentPage = 1;
    this.getSubCatContents();
  }
}
